import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import CustomerList from './components/CustomerList';
import CustomerPage from './components/CustomerPage';
import Dashboard from './components/Dashboard';
import ActiveTasksOverview from './components/ActiveTasksOverview';
import Login from './components/Login';
import { IoGridOutline, IoListOutline, IoLogOutOutline, IoMenuOutline, IoStatsChartOutline } from 'react-icons/io5';

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
  const [view, setView] = useState<'dashboard' | 'customer' | 'activeTasks'>('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSelectCustomer = (customerId: string) => {
    setSelectedCustomerId(customerId);
    setView('customer');
    setIsSidebarOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Login />;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={`${isSidebarOpen ? 'block' : 'hidden'} md:block w-full md:w-64 bg-white shadow-md fixed md:relative z-10`}>
        <CustomerList onSelectCustomer={handleSelectCustomer} />
      </div>
      
      {/* Main content area */}
      <div className="flex-1 overflow-auto">
        <div className="p-4 flex justify-between items-center bg-white shadow-sm">
          <div className="flex items-center">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="mr-2 md:hidden p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
            >
              <IoMenuOutline className="w-6 h-6 text-gray-600" />
            </button>
            <h1 className="text-2xl font-bold">
              {view === 'dashboard' ? 'Dashboard' : view === 'customer' ? 'Customer Details' : 'Active Tasks Overview'}
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setView('dashboard')}
              className={`p-2 rounded-full hover:bg-gray-200 transition-colors duration-200 ${view === 'dashboard' ? 'bg-gray-200' : ''}`}
              title="Dashboard View"
            >
              <IoGridOutline className="w-6 h-6 text-gray-600" />
            </button>
            <button
              onClick={() => setView('customer')}
              className={`p-2 rounded-full hover:bg-gray-200 transition-colors duration-200 ${view === 'customer' ? 'bg-gray-200' : ''}`}
              title="Customer View"
            >
              <IoListOutline className="w-6 h-6 text-gray-600" />
            </button>
            <button
              onClick={() => setView('activeTasks')}
              className={`p-2 rounded-full hover:bg-gray-200 transition-colors duration-200 ${view === 'activeTasks' ? 'bg-gray-200' : ''}`}
              title="Active Tasks Overview"
            >
              <IoStatsChartOutline className="w-6 h-6 text-gray-600" />
            </button>
            <button
              onClick={() => getAuth().signOut()}
              className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200 text-gray-600"
              title="Sign Out"
            >
              <IoLogOutOutline className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="p-4 md:p-8">
          {view === 'dashboard' && <Dashboard onSelectCustomer={handleSelectCustomer} />}
          {view === 'customer' && selectedCustomerId && <CustomerPage customerId={selectedCustomerId} />}
          {view === 'activeTasks' && <ActiveTasksOverview onSelectCustomer={handleSelectCustomer} />}
        </div>
      </div>
    </div>
  );
};

export default App;
