import React, { useEffect, useState } from 'react';
import { ref, onValue, update, get } from 'firebase/database';
import { db } from '../firebase';
import { IoPersonOutline, IoStarOutline, IoStarSharp } from 'react-icons/io5';

interface Customer {
  id: string;
  name: string;
  availablePoints: number;
  usedPoints: number;
  isFavorite: boolean;
  lastResetDate?: string;
}

interface DashboardProps {
  onSelectCustomer: (customerId: string) => void;
}

const Dashboard: React.FC<DashboardProps> = ({ onSelectCustomer }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    const customersRef = ref(db, 'customers');
    const unsubscribe = onValue(customersRef, async (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const customerList = await Promise.all(Object.entries(data).map(async ([id, customer]: [string, any]) => {
          const updatedCustomer = {
            id,
            name: customer.name,
            availablePoints: customer.availablePoints || 0,
            usedPoints: customer.usedPoints || 0,
            isFavorite: customer.isFavorite || false,
            lastResetDate: customer.lastResetDate,
          };

          await checkAndResetPoints(updatedCustomer);
          await fetchUsedPointsForCurrentMonth(updatedCustomer);

          return updatedCustomer;
        }));

        // Sort customers by remaining points (available - used)
        const sortedCustomers = customerList.sort((a, b) => 
          (b.availablePoints - b.usedPoints) - (a.availablePoints - a.usedPoints)
        );
        setCustomers(sortedCustomers);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkAndResetPoints = async (customer: Customer) => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const lastResetDate = customer.lastResetDate ? new Date(customer.lastResetDate) : null;

    if (!lastResetDate || lastResetDate.getMonth() !== currentMonth || lastResetDate.getFullYear() !== currentYear) {
      // Reset points
      const customerRef = ref(db, `customers/${customer.id}`);
      await update(customerRef, {
        usedPoints: 0,
        lastResetDate: now.toISOString(),
      });

      customer.usedPoints = 0;
      customer.lastResetDate = now.toISOString();
    }
  };

  const fetchUsedPointsForCurrentMonth = async (customer: Customer) => {
    const tasksRef = ref(db, `tasks/${customer.id}`);
    const snapshot = await get(tasksRef);
    const taskData = snapshot.val();
    if (taskData) {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      const usedPoints = Object.values(taskData).reduce((sum: number, task: any) => {
        const taskDate = new Date(task.completedAt || task.archivedAt || task.createdAt);
        if (
          (task.status === 'completed' || task.archivedAt) &&
          taskDate.getMonth() === currentMonth &&
          taskDate.getFullYear() === currentYear
        ) {
          return sum + (task.points || 0);
        }
        return sum;
      }, 0);

      customer.usedPoints = usedPoints;
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Customer Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {customers.map((customer) => (
          <div 
            key={customer.id} 
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={() => onSelectCustomer(customer.id)}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold flex items-center">
                <IoPersonOutline className="mr-2" />
                {customer.name}
              </h2>
              {customer.isFavorite ? (
                <IoStarSharp className="text-yellow-400 w-6 h-6" />
              ) : (
                <IoStarOutline className="text-gray-400 w-6 h-6" />
              )}
            </div>
            <div className="space-y-2">
              <p>Available Points: {customer.availablePoints}</p>
              <p>Used Points: {customer.usedPoints}</p>
              <p className="font-semibold">
                Remaining Points: {customer.availablePoints - customer.usedPoints}
              </p>
            </div>
            <div className="mt-4 bg-gray-200 h-2 rounded-full">
              <div 
                className="bg-blue-500 h-2 rounded-full"
                style={{ width: `${(customer.usedPoints / customer.availablePoints) * 100}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;