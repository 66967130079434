import React, { useState } from 'react';
import { ref, push } from 'firebase/database';
import { db } from '../firebase';
import { IoAdd, IoClose } from 'react-icons/io5';

interface TaskFormProps {
  customerId: string;
}

const TaskForm: React.FC<TaskFormProps> = ({ customerId }) => {
  const [title, setTitle] = useState('');
  const [points, setPoints] = useState('');
  const [priority, setPriority] = useState<'Low' | 'Mid' | 'High' | 'Urgent'>('Low');
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (title && points) {
      const tasksRef = ref(db, `tasks/${customerId}`);
      await push(tasksRef, {
        title,
        points: parseInt(points),
        status: 'idea',
        priority,
        createdAt: Date.now(),
      });
      setTitle('');
      setPoints('');
      setPriority('Low');
      setIsFormOpen(false);
    }
  };

  return (
    <div className="mt-4">
      {!isFormOpen ? (
        <button
          onClick={() => setIsFormOpen(true)}
          className="flex items-center justify-center w-full p-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors duration-300"
        >
          <IoAdd className="mr-2" />
          Add New Task
        </button>
      ) : (
        <div className="bg-white p-4 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-700">Add New Task</h2>
            <button
              onClick={() => setIsFormOpen(false)}
              className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
            >
              <IoClose className="w-5 h-5" />
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Task title"
              className="w-full p-2 border rounded"
              required
            />
            <input
              type="number"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
              placeholder="Points"
              className="w-full p-2 border rounded"
              required
            />
            <select
              value={priority}
              onChange={(e) => setPriority(e.target.value as 'Low' | 'Mid' | 'High' | 'Urgent')}
              className="w-full p-2 border rounded"
            >
              <option value="Low">Low</option>
              <option value="Mid">Mid</option>
              <option value="High">High</option>
              <option value="Urgent">Urgent</option>
            </select>
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
              Add Task
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default TaskForm;