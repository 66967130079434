import React, { useState, useEffect } from 'react';
import { ref, onValue, push, update, remove } from 'firebase/database';
import { db } from '../firebase';
import { IoAdd, IoTrash, IoChevronDown, IoChevronUp, IoClose } from 'react-icons/io5';

interface WebDesignProject {
  id: string;
  name: string;
  progress: number;
  steps: { [key: string]: boolean };
  priority: 'Low' | 'Mid' | 'High' | 'Urgent';
}

const WebDesignProjects: React.FC<{ customerId: string }> = ({ customerId }) => {
  const [projects, setProjects] = useState<WebDesignProject[]>([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [expandedSteps, setExpandedSteps] = useState<{ [key: string]: boolean }>({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [newProjectPriority, setNewProjectPriority] = useState<'Low' | 'Mid' | 'High' | 'Urgent'>('Low');

  useEffect(() => {
    const projectsRef = ref(db, `webDesignProjects/${customerId}`);
    const unsubscribe = onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const projectList = Object.entries(data).map(([id, project]: [string, any]) => ({
          id,
          ...project,
        }));
        setProjects(projectList);
      } else {
        setProjects([]);
      }
    });

    return () => unsubscribe();
  }, [customerId]);

  const addProject = async () => {
    if (newProjectName.trim()) {
      const projectsRef = ref(db, `webDesignProjects/${customerId}`);
      await push(projectsRef, {
        name: newProjectName.trim(),
        progress: 0,
        steps: initialSteps,
        priority: newProjectPriority,
      });
      setNewProjectName('');
      setNewProjectPriority('Low');
      setIsFormOpen(false);
    }
  };

  const updateProjectProgress = async (projectId: string, steps: { [key: string]: boolean }) => {
    const completedSteps = Object.values(steps).filter(Boolean).length;
    const progress = Math.round((completedSteps / Object.keys(steps).length) * 100);
    const projectRef = ref(db, `webDesignProjects/${customerId}/${projectId}`);
    await update(projectRef, { steps, progress });
  };

  const deleteProject = async (projectId: string) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      const projectRef = ref(db, `webDesignProjects/${customerId}/${projectId}`);
      await remove(projectRef);
    }
  };

  const toggleStepExpansion = (stepGroup: string) => {
    setExpandedSteps(prev => ({ ...prev, [stepGroup]: !prev[stepGroup] }));
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold mb-4">Web Design Projects</h2>
      
      {/* Add new project form */}
      <div className="mt-4">
        {!isFormOpen ? (
          <button
            onClick={() => setIsFormOpen(true)}
            className="flex items-center justify-center w-full p-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors duration-300"
          >
            <IoAdd className="mr-2" />
            Create New Web Design Project
          </button>
        ) : (
          <div className="bg-white p-4 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-gray-700">Create New Web Design Project</h2>
              <button
                onClick={() => setIsFormOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
              >
                <IoClose className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <input
                type="text"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                placeholder="Project name"
                className="w-full p-2 border rounded"
                required
              />
              <select
                value={newProjectPriority}
                onChange={(e) => setNewProjectPriority(e.target.value as 'Low' | 'Mid' | 'High' | 'Urgent')}
                className="w-full p-2 border rounded"
              >
                <option value="Low">Low</option>
                <option value="Mid">Mid</option>
                <option value="High">High</option>
                <option value="Urgent">Urgent</option>
              </select>
              <button
                onClick={addProject}
                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
              >
                Create Project
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Project list */}
      {projects.map((project) => (
        <div key={project.id} className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">{project.name}</h3>
            <button
              onClick={() => deleteProject(project.id)}
              className="text-red-500 hover:text-red-700"
            >
              <IoTrash className="w-5 h-5" />
            </button>
          </div>
          <div className="mb-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${project.progress}%` }}
              ></div>
            </div>
            <p className="text-right text-sm text-gray-600 mt-1">{project.progress}% complete</p>
          </div>
          <div className="space-y-4">
            {Object.entries(stepGroups).map(([groupName, steps]) => (
              <div key={groupName} className="border rounded-lg p-2">
                <button
                  className="flex justify-between items-center w-full text-left font-semibold"
                  onClick={() => toggleStepExpansion(groupName)}
                >
                  <span>{groupName}</span>
                  {expandedSteps[groupName] ? <IoChevronUp /> : <IoChevronDown />}
                </button>
                {expandedSteps[groupName] && (
                  <div className="mt-2 space-y-2">
                    {steps.map((step) => (
                      <div key={step.id} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={project.steps[step.id]}
                          onChange={() => {
                            const newSteps = { ...project.steps, [step.id]: !project.steps[step.id] };
                            updateProjectProgress(project.id, newSteps);
                          }}
                          className="mr-2"
                        />
                        <span className={project.steps[step.id] ? 'line-through text-gray-500' : ''}>{step.text}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const stepGroups = {
  'Steg 1: Förberedelser': [
    { id: 'kravinsamling', text: 'Kravinsamling: Diskutera och förstå klientens behov och mål med webbplatsen.' },
    { id: 'utvecklingsplan', text: 'Skapa en utvecklingsplan: Gör en grov plan över webbplatsens struktur, innehåll, design, och funktioner.' },
  ],
  'Steg 2: Skapa Test- eller Dev-sida': [
    { id: 'testmiljo', text: 'Skapa en testmiljö: Installera WordPress på en lokal server eller på en testdomän.' },
    { id: 'installera_wordpress', text: 'Installera WordPress: Ladda ner och installera WordPress på testmiljön.' },
    { id: 'sakra_sidan', text: 'Säkra sidan: Skydda testmiljön med lösenordsskydd och HTTPS om nödvändigt.' },
  ],
  'Steg 3: Design och Tema': [
    { id: 'valj_tema', text: 'Välj ett tema: Välj och installera ett tema som passar projektet.' },
    { id: 'skapa_barntema', text: 'Skapa ett barn-tema: Skapa ett child theme om du ska anpassa tema-filer.' },
    { id: 'installera_plugins', text: 'Installera nödvändiga plugins: Installera de plugins som krävs för webbplatsens funktioner.' },
    { id: 'anpassa_design', text: 'Anpassa designen: Anpassa temat enligt designplanen med hjälp av anpassaren, CSS, och eventuell plugin-användning (t.ex. Elementor).' },
    { id: 'skapa_sidmallar', text: 'Skapa sidmallar: Om ni använder Elementor, skapa de sidmallar ni behöver för layout och design.' },
  ],
  'Steg 4: Struktur och Innehåll': [
    { id: 'skapa_sidor_menyer', text: 'Skapa sidor och menyer: Skapa webbplatsens sidor och konfigurera navigeringsmenyer.' },
    { id: 'bygg_sidstruktur', text: 'Bygg sidstrukturen: Skapa och designa startsidan, kontaktsidan, och andra viktiga sidor.' },
    { id: 'lagg_till_innehall', text: 'Lägg till innehåll: Lägg till text, bilder och andra medier enligt planen.' },
    { id: 'installera_funktionella_plugins', text: 'Installera plugins för funktionalitet: Tillämpa funktionella plugins, t.ex. formulärbyggare, e-handelsfunktioner, språkomkopplare.' },
  ],
  'Steg 5: SEO och Prestanda': [
    { id: 'installera_seo_plugin', text: 'Installera SEO-plugin: Installera och konfigurera SEO-plugins som Yoast eller Rank Math.' },
    { id: 'optimera_bilder_innehall', text: 'Optimera bilder och innehåll: Komprimera bilder och optimera text för sökmotorer.' },
    { id: 'konfigurera_caching', text: 'Konfigurera caching: Installera ett cache-plugin för att förbättra laddningstider.' },
  ],
  'Steg 6: Testning': [
    { id: 'testa_funktionalitet', text: 'Testa funktionaliteten: Kontrollera alla funktioner (t.ex. formulär, knappar) och se till att de fungerar korrekt.' },
    { id: 'responsiv_testning', text: 'Responsiv testning: Testa webbplatsen på olika enheter och webbläsare för att säkerställa responsivitet.' },
    { id: 'felsökning', text: 'Felsökning: Korrigera eventuella problem eller buggar.' },
  ],
  'Steg 7: Förbered för lansering': [
    { id: 'skapa_sakerhetskopia', text: 'Skapa en säkerhetskopia: Säkerhetskopiera hela webbplatsen, inklusive databasen.' },
    { id: 'flytta_till_live', text: 'Flytta till live-server: Migrera webbplatsen från testmiljön till den skarpa domänen.' },
    { id: 'satt_upp_omdirigeringar', text: 'Sätt upp omdirigeringar: Om det finns en äldre webbplats, sätt upp omdirigeringar från gamla URL till de nya.' },
    { id: 'ssl_certifikat', text: 'SSL-certifikat: Aktivera SSL på live-webbplatsen.' },
  ],
  'Steg 8: Efter lansering': [
    { id: 'kontrollera_seo', text: 'Kontrollera SEO-inställningar: Kontrollera att webbplatsen är synlig för sökmotorer.' },
    { id: 'testa_funktionalitet_igen', text: 'Testa funktionaliteten igen: Gör en slutlig testning av alla funktioner på live-webbplatsen.' },
    { id: 'konfigurera_analytics', text: 'Konfigurera Google Analytics: Lägg till spårningskoder för Google Analytics eller andra analysverktyg.' },
    { id: 'sakerhetsatgarder', text: 'Säkerhetsåtgärder: Installera säkerhetsplugins och konfigurera automatiska säkerhetskopieringar.' },
  ],
};

const initialSteps = Object.values(stepGroups).reduce((acc, steps) => {
  steps.forEach(step => {
    acc[step.id] = false;
  });
  return acc as { [key: string]: boolean };
}, {} as { [key: string]: boolean });

export default WebDesignProjects;