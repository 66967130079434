import React, { useState, useEffect } from 'react';
import { ref, update, onValue } from 'firebase/database';
import { db } from '../firebase';

interface CustomerSettingsProps {
  customerId: string;
}

const CustomerSettings: React.FC<CustomerSettingsProps> = ({ customerId }) => {
  const [availablePoints, setAvailablePoints] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const customerRef = ref(db, `customers/${customerId}`);
    onValue(customerRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.availablePoints) {
        setAvailablePoints(data.availablePoints.toString());
      }
    });
  }, [customerId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const customerRef = ref(db, `customers/${customerId}`);
    await update(customerRef, { availablePoints: parseInt(availablePoints) });
    
    // Show notification
    setShowNotification(true);
    
    // Hide notification after 3 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <div className="mb-8 bg-white p-6 rounded-lg shadow relative">
      <h2 className="text-xl font-bold mb-4">Customer Settings</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="availablePoints" className="block text-sm font-medium text-gray-700">
            Available Points
          </label>
          <input
            type="number"
            id="availablePoints"
            value={availablePoints}
            onChange={(e) => setAvailablePoints(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="Enter available points"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Save Settings
        </button>
      </form>
      
      {showNotification && (
        <div className="absolute bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg transition-opacity duration-300">
          Points updated successfully!
        </div>
      )}
    </div>
  );
};

export default CustomerSettings;