import React, { useEffect, useState } from 'react';
import { ref, onValue, update, remove } from 'firebase/database';
import { db } from '../firebase';
import { IoArrowForward, IoArrowBack, IoTrash, IoPencil, IoArchive } from 'react-icons/io5';
import TaskForm from './TaskForm';

interface Task {
  id: string;
  title: string;
  status: 'idea' | 'in_progress' | 'completed';
  points: number;
  description?: string;
  archivedAt?: string;
}

const columns = [
  { id: 'idea', title: 'Idea' },
  { id: 'in_progress', title: 'In Progress' },
  { id: 'completed', title: 'Completed' },
];

const TaskList: React.FC<{ customerId: string }> = ({ customerId }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const tasksRef = ref(db, `tasks/${customerId}`);
    onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const taskList = Object.entries(data)
          .map(([id, task]: [string, any]) => ({
            id,
            ...task,
          }))
          .filter(task => !task.archivedAt);
        console.log('Fetched tasks:', taskList); // Add this line for debugging
        setTasks(taskList);
      } else {
        setTasks([]);
      }
    });
  }, [customerId]);

  const moveTask = async (taskId: string, currentStatus: string, direction: 'forward' | 'backward') => {
    const currentIndex = columns.findIndex(col => col.id === currentStatus);
    let newStatus;
    if (direction === 'forward' && currentIndex < columns.length - 1) {
      newStatus = columns[currentIndex + 1].id;
    } else if (direction === 'backward' && currentIndex > 0) {
      newStatus = columns[currentIndex - 1].id;
    }
    if (newStatus) {
      const taskRef = ref(db, `tasks/${customerId}/${taskId}`);
      await update(taskRef, { status: newStatus });
    }
  };

  const deleteTask = async (taskId: string) => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      const taskRef = ref(db, `tasks/${customerId}/${taskId}`);
      await remove(taskRef);
    }
  };

  const archiveTask = async (taskId: string) => {
    const taskRef = ref(db, `tasks/${customerId}/${taskId}`);
    await update(taskRef, { 
      archivedAt: new Date().toISOString(),
      status: 'completed'
    });
  };

  const openEditModal = (task: Task) => {
    setEditingTask(task);
    setDescription(task.description || '');
  };

  const closeEditModal = () => {
    setEditingTask(null);
    setDescription('');
  };

  const saveDescription = async () => {
    if (editingTask) {
      const taskRef = ref(db, `tasks/${customerId}/${editingTask.id}`);
      await update(taskRef, { description });
      closeEditModal();
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">SEO Tasks</h2>
      <TaskForm customerId={customerId} />
      <div className="md:hidden overflow-x-auto mt-4">
        <div className="flex space-x-4 min-w-max">
          {columns.map((column) => (
            <div key={column.id} className="w-72 bg-white p-4 rounded-xl shadow-md">
              <h3 className="text-base font-semibold mb-4 text-gray-700 capitalize">{column.title}</h3>
              <div className="space-y-3">
                {tasks
                  .filter((task) => task.status === column.id)
                  .map((task) => (
                    <div 
                      key={task.id} 
                      className="bg-gray-50 p-3 rounded-lg shadow-sm transition-shadow duration-300 hover:shadow-md cursor-pointer"
                      onDoubleClick={() => openEditModal(task)}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="text-sm font-medium text-gray-800">{task.title}</h4>
                        <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs font-semibold">
                          {task.points} pts
                        </span>
                      </div>
                      {renderTaskActions(task, column.id)}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="hidden md:block mt-4">
        <div className="grid grid-cols-3 gap-6">
          {columns.map((column) => (
            <div key={column.id} className="bg-white p-4 rounded-xl shadow-md">
              <h3 className="text-lg font-semibold mb-4 text-gray-700 capitalize">{column.title}</h3>
              <div className="space-y-3">
                {tasks
                  .filter((task) => task.status === column.id)
                  .map((task) => (
                    <div 
                      key={task.id} 
                      className="bg-gray-50 p-3 rounded-lg shadow-sm transition-shadow duration-300 hover:shadow-md cursor-pointer"
                      onDoubleClick={() => openEditModal(task)}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="text-base font-medium text-gray-800">{task.title}</h4>
                        <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs font-semibold">
                          {task.points} pts
                        </span>
                      </div>
                      {renderTaskActions(task, column.id)}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {renderEditModal()}
    </div>
  );

  function renderTaskActions(task: Task, columnId: string) {
    return (
      <div className="flex justify-between items-center">
        {columnId !== 'idea' && (
          <button
            onClick={() => moveTask(task.id, task.status, 'backward')}
            className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
            aria-label="Move task backward"
          >
            <IoArrowBack className="w-4 h-4" />
          </button>
        )}
        <div className="flex items-center space-x-1">
          <button
            onClick={() => openEditModal(task)}
            className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
            aria-label="Edit task"
          >
            <IoPencil className="w-4 h-4" />
          </button>
          {columnId !== 'completed' && (
            <button
              onClick={() => moveTask(task.id, task.status, 'forward')}
              className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
              aria-label="Move task forward"
            >
              <IoArrowForward className="w-4 h-4" />
            </button>
          )}
          {columnId === 'completed' && (
            <button
              onClick={() => archiveTask(task.id)}
              className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
              aria-label="Archive task"
            >
              <IoArchive className="w-4 h-4" />
            </button>
          )}
          <button
            onClick={() => deleteTask(task.id)}
            className="p-1 text-gray-600 hover:text-red-600 transition-colors duration-300"
            aria-label="Delete task"
          >
            <IoTrash className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  function renderEditModal() {
    if (!editingTask) return null;
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
          <h3 className="text-xl font-bold mb-4 text-gray-800">{editingTask.title}</h3>
          <textarea
            className="w-full h-64 p-2 border border-gray-300 rounded mb-4 text-gray-700"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter task description..."
          />
          <div className="flex justify-end space-x-2">
            <button
              onClick={closeEditModal}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors duration-300"
            >
              Cancel
            </button>
            <button
              onClick={saveDescription}
              className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition-colors duration-300"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default TaskList;