import React, { useEffect, useState } from 'react';
import { ref, onValue, remove, update, set } from 'firebase/database';
import { db } from '../firebase';
import { IoAdd, IoTrash, IoCreate, IoEllipsisVertical, IoStar, IoStarOutline, IoSearch } from 'react-icons/io5';
import AddCustomerModal from './AddCustomerModal';
import EditCustomerModal from './EditCustomerModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

interface Customer {
  id: string;
  name: string;
  isFavorite?: boolean;
}

interface CustomerListProps {
  onSelectCustomer: (customerId: string) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({ onSelectCustomer }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    const customersRef = ref(db, 'customers');
    onValue(customersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const customerList = Object.entries(data).map(([id, customer]: [string, any]) => ({
          id,
          name: customer.name,
          isFavorite: customer.isFavorite || false,
        }));
        const sortedCustomers = customerList.sort((a, b) => {
          if (a.isFavorite === b.isFavorite) {
            return a.name.localeCompare(b.name);
          }
          return a.isFavorite ? -1 : 1;
        });
        setCustomers(sortedCustomers);
        setFilteredCustomers(sortedCustomers);
      }
    });
  }, []);

  useEffect(() => {
    const filtered = customers.filter(customer =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustomers(filtered);
  }, [searchTerm, customers]);

  const handleAddCustomer = () => {
    setIsAddModalOpen(true);
  };

  const handleDeleteCustomer = async (customerId: string) => {
    const customerRef = ref(db, `customers/${customerId}`);
    await remove(customerRef);
    // Also remove all tasks associated with this customer
    const tasksRef = ref(db, `tasks/${customerId}`);
    await remove(tasksRef);
  };

  const handleEditCustomer = async (customerId: string, newName: string) => {
    const customerRef = ref(db, `customers/${customerId}`);
    await update(customerRef, { name: newName });
  };

  const toggleFavorite = async (customer: Customer) => {
    const customerRef = ref(db, `customers/${customer.id}`);
    await set(customerRef, { ...customer, isFavorite: !customer.isFavorite });
  };

  const toggleDropdown = (customer: Customer, event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenDropdown(openDropdown === customer.id ? null : customer.id);
    setSelectedCustomer(customer);
  };

  const openEditModal = (customer: Customer, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedCustomer(customer);
    setIsEditModalOpen(true);
    setOpenDropdown(null);
  };

  const openDeleteModal = (customer: Customer, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedCustomer(customer);
    setIsDeleteModalOpen(true);
    setOpenDropdown(null);
  };

  return (
    <div className="p-4 flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Customers</h2>
        <button
          onClick={handleAddCustomer}
          className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
          aria-label="Add customer"
        >
          <IoAdd className="w-6 h-6 text-gray-700" />
        </button>
      </div>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search customers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>
      <ul className="flex-grow overflow-auto">
        {filteredCustomers.map((customer) => (
          <li
            key={customer.id}
            className="mb-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
            onClick={() => onSelectCustomer(customer.id)}
          >
            <div className="flex items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(customer);
                }}
                className={`mr-2 ${customer.isFavorite ? 'text-yellow-400 hover:text-yellow-500' : 'text-gray-400 hover:text-gray-500'}`}
                aria-label={customer.isFavorite ? "Remove from favorites" : "Add to favorites"}
              >
                {customer.isFavorite ? <IoStar className="w-5 h-5" /> : <IoStarOutline className="w-5 h-5" />}
              </button>
              <span>{customer.name}</span>
            </div>
            <div className="relative">
              <button
                onClick={(e) => toggleDropdown(customer, e)}
                className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
                aria-label="Options"
              >
                <IoEllipsisVertical className="w-5 h-5" />
              </button>
              {openDropdown === customer.id && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <button
                    onClick={(e) => openEditModal(customer, e)}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <IoCreate className="inline-block mr-2" /> Edit Name
                  </button>
                  <button
                    onClick={(e) => openDeleteModal(customer, e)}
                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    <IoTrash className="inline-block mr-2" /> Delete
                  </button>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
      <AddCustomerModal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
      {selectedCustomer && (
        <>
          <EditCustomerModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onSave={(newName) => handleEditCustomer(selectedCustomer.id, newName)}
            currentName={selectedCustomer.name}
          />
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={() => handleDeleteCustomer(selectedCustomer.id)}
            customerName={selectedCustomer.name}
          />
        </>
      )}
    </div>
  );
};

export default CustomerList;