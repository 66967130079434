import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase';
import TaskList from './TaskList';
import TaskForm from './TaskForm';
import PointsOverview from './PointsOverview';
import CustomerSettings from './CustomerSettings';
import SupportTicketList from './SupportTicketList';
import SupportTicketForm from './SupportTicketForm';
import Docs from './Docs';
import WebDesignProjects from './WebDesignProjects';
import { IoListOutline, IoStatsChartOutline, IoSettingsOutline, IoHelpCircleOutline, IoDocumentTextOutline, IoMenuOutline, IoBrushOutline } from 'react-icons/io5';

interface Customer {
  id: string;
  name: string;
  availablePoints?: number;
}

const CustomerPage: React.FC<{ customerId: string }> = ({ customerId }) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [activeTab, setActiveTab] = useState('seo-tasks');
  const [totalPoints, setTotalPoints] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const customerRef = ref(db, `customers/${customerId}`);
    const unsubscribeCustomer = onValue(customerRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCustomer({ id: customerId, name: data.name, availablePoints: data.availablePoints });
      } else {
        setCustomer(null);
      }
    });

    const tasksRef = ref(db, `tasks/${customerId}`);
    const unsubscribeTasks = onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const tasks = Object.values(data) as any[];
        const total = tasks.reduce((sum, task) => {
          // Only count points for non-archived tasks
          return task.archivedAt ? sum : sum + (task.points || 0);
        }, 0);
        setTotalPoints(total);
      } else {
        setTotalPoints(0);
      }
    });

    // Reset states when customerId changes
    setActiveTab('seo-tasks');
    setIsMobileMenuOpen(false);

    // Cleanup function
    return () => {
      unsubscribeCustomer();
      unsubscribeTasks();
    };
  }, [customerId]);

  if (!customer) {
    return <div>Loading...</div>;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'seo-tasks':
        return (
          <>
            <TaskList customerId={customer.id} />
            <TaskForm customerId={customer.id} />
          </>
        );
      case 'seo-points':
        return (
          <PointsOverview customerId={customer.id} availablePoints={customer.availablePoints} />
        );
      case 'seo-settings':
        return <CustomerSettings customerId={customer.id} />;
      case 'support':
        return (
          <>
            <SupportTicketList customerId={customer.id} />
            <SupportTicketForm customerId={customer.id} />
          </>
        );
      case 'docs':
        return <Docs customerId={customer.id} />;
      case 'web-design':
        return <WebDesignProjects customerId={customer.id} />;
      default:
        return null;
    }
  };

  const renderNavButton = (tabName: string, icon: React.ReactNode, label: string) => (
    <button
      className={`flex items-center ${activeTab === tabName ? 'text-blue-600 font-semibold' : 'text-gray-600'} px-4 py-2 rounded-md hover:bg-gray-100`}
      onClick={() => {
        setActiveTab(tabName);
        setIsMobileMenuOpen(false);
      }}
    >
      {icon}
      <span className="ml-2">{label}</span>
    </button>
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
      <div className="flex justify-between items-center mb-4 md:mb-6">
        <h1 className="text-xl md:text-2xl font-bold">{customer.name}</h1>
        <div className="text-sm md:text-lg font-semibold">
          Total: {totalPoints} / Available: {customer.availablePoints || 0}
        </div>
      </div>
      
      {/* Mobile menu button */}
      <div className="md:hidden mb-4">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-500 hover:text-gray-700 hover:border-gray-700"
        >
          <IoMenuOutline className="h-5 w-5" />
        </button>
      </div>

      {/* Navigation */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block mb-6`}>
        {/* Desktop view */}
        <div className="hidden md:flex md:justify-between md:border-b md:pb-4">
          <div className="flex space-x-4">
            {renderNavButton('seo-tasks', <IoListOutline className="w-5 h-5" />, 'Tasks')}
            {renderNavButton('seo-points', <IoStatsChartOutline className="w-5 h-5" />, 'Points')}
            {renderNavButton('seo-settings', <IoSettingsOutline className="w-5 h-5" />, 'Settings')}
          </div>
          <div className="flex space-x-4">
            {renderNavButton('support', <IoHelpCircleOutline className="w-5 h-5" />, 'Support')}
            {renderNavButton('docs', <IoDocumentTextOutline className="w-5 h-5" />, 'Docs')}
            {renderNavButton('web-design', <IoBrushOutline className="w-5 h-5" />, 'Web Design')}
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden">
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2">SEO</h2>
            <div className="flex flex-col space-y-2">
              {renderNavButton('seo-tasks', <IoListOutline className="w-5 h-5" />, 'Tasks')}
              {renderNavButton('seo-points', <IoStatsChartOutline className="w-5 h-5" />, 'Points')}
              {renderNavButton('seo-settings', <IoSettingsOutline className="w-5 h-5" />, 'Settings')}
            </div>
          </div>
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2">Support</h2>
            <div className="flex flex-col space-y-2">
              {renderNavButton('support', <IoHelpCircleOutline className="w-5 h-5" />, 'Tickets')}
            </div>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Docs</h2>
            <div className="flex flex-col space-y-2">
              {renderNavButton('docs', <IoDocumentTextOutline className="w-5 h-5" />, 'Documents')}
            </div>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Web Design</h2>
            <div className="flex flex-col space-y-2">
              {renderNavButton('web-design', <IoBrushOutline className="w-5 h-5" />, 'Projects')}
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="mt-6">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default CustomerPage;