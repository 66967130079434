import React, { useEffect, useState } from 'react';
import { ref, onValue, get, update } from 'firebase/database';
import { db } from '../firebase';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

interface PointsOverviewProps {
  customerId: string;
  availablePoints?: number;
}

interface Task {
  id: string;
  title: string;
  points: number;
  status: string;
  archivedAt?: number;
  completedAt?: number;
  createdAt: number;
}

interface MonthlyTasks {
  [key: string]: Task[];
}

const PointsOverview: React.FC<PointsOverviewProps> = ({ customerId, availablePoints }) => {
  const [totalPoints, setTotalPoints] = useState(0);
  const [usedPoints, setUsedPoints] = useState(0);
  const [monthlyTasks, setMonthlyTasks] = useState<MonthlyTasks>({});
  const [expandedMonths, setExpandedMonths] = useState<string[]>([]);
  const [lastResetDate, setLastResetDate] = useState<Date | null>(null);

  useEffect(() => {
    const tasksRef = ref(db, `tasks/${customerId}`);
    const customerRef = ref(db, `customers/${customerId}`);

    const fetchData = async () => {
      const customerSnapshot = await get(customerRef);
      const customerData = customerSnapshot.val();
      if (customerData && customerData.lastResetDate) {
        setLastResetDate(new Date(customerData.lastResetDate));
      }

      onValue(tasksRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const tasks = Object.entries(data).map(([id, task]: [string, any]) => ({
            id,
            ...task,
          }));

          const now = new Date();
          const currentMonth = now.getMonth();
          const currentYear = now.getFullYear();

          const total = tasks.reduce((sum, task) => sum + task.points, 0);
          const used = tasks.filter(task => {
            const taskDate = new Date(task.completedAt || task.archivedAt || task.createdAt);
            return (task.status === 'completed' || task.archivedAt) && 
                   !isNaN(taskDate.getTime()) &&
                   taskDate.getMonth() === currentMonth && 
                   taskDate.getFullYear() === currentYear;
          }).reduce((sum, task) => sum + task.points, 0);

          setTotalPoints(total);
          setUsedPoints(used);

          const monthlyTasksData: MonthlyTasks = {};
          tasks.forEach((task: Task) => {
            if (task.status === 'completed' || task.archivedAt) {
              const date = new Date(task.archivedAt || task.completedAt || task.createdAt);
              if (!isNaN(date.getTime())) {
                const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                if (!monthlyTasksData[monthYear]) {
                  monthlyTasksData[monthYear] = [];
                }
                monthlyTasksData[monthYear].push(task);
              }
            }
          });
          setMonthlyTasks(monthlyTasksData);
        }
      });
    };

    fetchData();
  }, [customerId]);

  useEffect(() => {
    const checkAndResetPoints = async () => {
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      if (!lastResetDate || lastResetDate.getMonth() !== currentMonth || lastResetDate.getFullYear() !== currentYear) {
        // Reset points
        const customerRef = ref(db, `customers/${customerId}`);
        await update(customerRef, {
          usedPoints: 0,
          lastResetDate: now.toISOString(),
        });

        setLastResetDate(now);
      }
    };

    checkAndResetPoints();
  }, [customerId, lastResetDate]);

  const remainingPoints = availablePoints ? availablePoints - usedPoints : 0;
  const progressPercentage = availablePoints ? (usedPoints / availablePoints) * 100 : 0;

  const toggleMonth = (month: string) => {
    setExpandedMonths(prev =>
      prev.includes(month) ? prev.filter(m => m !== month) : [...prev, month]
    );
  };

  const formatMonth = (monthYear: string) => {
    const [year, month] = monthYear.split('-');
    return new Date(parseInt(year), parseInt(month) - 1).toLocaleString('default', { month: 'long', year: 'numeric' });
  };

  return (
    <div className="mb-8 bg-white p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Points Overview</h2>
      <div className="mb-4">
        <div className="flex justify-between mb-1">
          <span>Progress</span>
          <span>{progressPercentage.toFixed(0)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 text-center mb-6">
        <div>
          <p className="text-sm text-gray-600">Available Points</p>
          <p className="text-lg font-semibold">{availablePoints || 0}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Used Points</p>
          <p className="text-lg font-semibold">{usedPoints}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Remaining Points</p>
          <p className="text-lg font-semibold">{remainingPoints}</p>
        </div>
      </div>
      <h3 className="text-lg font-semibold mb-2">Monthly Breakdown</h3>
      <div className="space-y-2">
        {Object.entries(monthlyTasks)
          .sort(([a], [b]) => b.localeCompare(a))
          .map(([month, tasks]) => {
            const monthlyTotal = tasks.reduce((sum, task) => sum + task.points, 0);
            return (
              <div key={month} className="border rounded-lg">
                <button
                  className="w-full px-4 py-2 text-left flex justify-between items-center"
                  onClick={() => toggleMonth(month)}
                >
                  <span>{formatMonth(month)}</span>
                  <div className="flex items-center">
                    <span className="mr-2">{monthlyTotal} points</span>
                    {expandedMonths.includes(month) ? <IoChevronUp /> : <IoChevronDown />}
                  </div>
                </button>
                {expandedMonths.includes(month) && (
                  <div className="px-4 py-2 bg-gray-50">
                    <ul className="space-y-1">
                      {tasks.map(task => (
                        <li key={task.id} className="flex justify-between items-center">
                          <span>{task.title}</span>
                          <span>{task.points} points</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PointsOverview;