import React, { useEffect, useState } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { db } from '../firebase';
import { IoListOutline, IoHelpCircleOutline, IoBrushOutline } from 'react-icons/io5';

interface Task {
  id: string;
  title: string;
  status: string;
  points?: number;
  timeSpent?: number;
  type: 'seo' | 'support' | 'web-design';
  customerId: string;
  customerName: string;
  progress?: number;
  priority: 'Low' | 'Mid' | 'High' | 'Urgent';
}

interface ActiveTasksOverviewProps {
  onSelectCustomer: (customerId: string) => void;
}

const priorityColors = {
  Low: 'bg-blue-100 text-blue-800',
  Mid: 'bg-green-100 text-green-800',
  High: 'bg-yellow-100 text-yellow-800',
  Urgent: 'bg-red-100 text-red-800',
};

const ActiveTasksOverview: React.FC<ActiveTasksOverviewProps> = ({ onSelectCustomer }) => {
  const [activeTasks, setActiveTasks] = useState<Task[]>([]);

  useEffect(() => {
    const customersRef = ref(db, 'customers');
    const tasksRef = ref(db, 'tasks');
    const supportTicketsRef = ref(db, 'supportTickets');
    const webDesignProjectsRef = ref(db, 'webDesignProjects');

    const unsubscribeCustomers = onValue(customersRef, (snapshot) => {
      const customersData = snapshot.val();
      
      const unsubscribeTasks = onValue(tasksRef, (taskSnapshot) => {
        const tasksData = taskSnapshot.val();
        
        const unsubscribeSupport = onValue(supportTicketsRef, (supportSnapshot) => {
          const supportData = supportSnapshot.val();
          
          const unsubscribeWebDesign = onValue(webDesignProjectsRef, (webDesignSnapshot) => {
            const webDesignData = webDesignSnapshot.val();
            
            const allTasks: Task[] = [];

            // Process SEO tasks
            Object.entries(tasksData || {}).forEach(([customerId, customerTasks]: [string, any]) => {
              Object.entries(customerTasks).forEach(([taskId, task]: [string, any]) => {
                if (task.status !== 'completed') {
                  allTasks.push({
                    id: taskId,
                    title: task.title,
                    status: task.status,
                    points: task.points,
                    type: 'seo',
                    customerId,
                    customerName: customersData[customerId]?.name || 'Unknown',
                    priority: task.priority || 'Low',
                  });
                }
              });
            });

            // Process Support tickets
            Object.entries(supportData || {}).forEach(([customerId, customerTickets]: [string, any]) => {
              Object.entries(customerTickets).forEach(([ticketId, ticket]: [string, any]) => {
                if (ticket.status !== 'completed') {
                  allTasks.push({
                    id: ticketId,
                    title: ticket.title,
                    status: ticket.status,
                    timeSpent: ticket.timeSpent,
                    type: 'support',
                    customerId,
                    customerName: customersData[customerId]?.name || 'Unknown',
                    priority: ticket.priority || 'Low',
                  });
                }
              });
            });

            // Process Web Design projects
            Object.entries(webDesignData || {}).forEach(([customerId, customerProjects]: [string, any]) => {
              Object.entries(customerProjects).forEach(([projectId, project]: [string, any]) => {
                if (project.progress < 100) {
                  allTasks.push({
                    id: projectId,
                    title: project.name,
                    status: 'in_progress',
                    type: 'web-design',
                    customerId,
                    customerName: customersData[customerId]?.name || 'Unknown',
                    progress: project.progress,
                    priority: project.priority || 'Low',
                  });
                }
              });
            });

            // Sort the tasks
            const sortedTasks = sortTasksByPriority(allTasks);
            setActiveTasks(sortedTasks);
          });

          return () => {
            unsubscribeWebDesign();
          };
        });

        return () => {
          unsubscribeSupport();
        };
      });

      return () => {
        unsubscribeTasks();
      };
    });

    return () => {
      unsubscribeCustomers();
    };
  }, []);

  const formatTime = (seconds: number = 0) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const handlePriorityChange = async (task: Task, newPriority: Task['priority']) => {
    let dbRef;
    switch (task.type) {
      case 'seo':
        dbRef = ref(db, `tasks/${task.customerId}/${task.id}`);
        break;
      case 'support':
        dbRef = ref(db, `supportTickets/${task.customerId}/${task.id}`);
        break;
      case 'web-design':
        dbRef = ref(db, `webDesignProjects/${task.customerId}/${task.id}`);
        break;
    }
    if (dbRef) {
      await update(dbRef, { priority: newPriority });
    }
  };

  // New sorting function
  const sortTasksByPriority = (tasks: Task[]): Task[] => {
    const priorityOrder = { Urgent: 0, High: 1, Mid: 2, Low: 3 };
    return tasks.sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Active Tasks Overview</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">Customer</th>
              <th className="py-3 px-4 text-left">Type</th>
              <th className="py-3 px-4 text-left">Title</th>
              <th className="py-3 px-4 text-left">Status</th>
              <th className="py-3 px-4 text-left">Points/Time/Progress</th>
              <th className="py-3 px-4 text-left">Priority</th>
            </tr>
          </thead>
          <tbody>
            {activeTasks.map((task) => (
              <tr key={`${task.type}-${task.id}`} className="border-b border-gray-200 hover:bg-gray-100">
                <td 
                  className="py-3 px-4 cursor-pointer text-[#2e2e2e] hover:underline"
                  onClick={() => onSelectCustomer(task.customerId)}
                >
                  {task.customerName}
                </td>
                <td className="py-3 px-4">
                  {task.type === 'seo' && <IoListOutline className="inline-block mr-2" />}
                  {task.type === 'support' && <IoHelpCircleOutline className="inline-block mr-2" />}
                  {task.type === 'web-design' && <IoBrushOutline className="inline-block mr-2" />}
                  {task.type === 'seo' ? 'SEO' : task.type === 'support' ? 'Support' : 'Web Design'}
                </td>
                <td className="py-3 px-4">{task.title}</td>
                <td className="py-3 px-4 capitalize">{task.status.replace('_', ' ')}</td>
                <td className="py-3 px-4">
                  {task.type === 'seo' && `${task.points} pts`}
                  {task.type === 'support' && formatTime(task.timeSpent)}
                  {task.type === 'web-design' && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${task.progress}%` }}
                      ></div>
                    </div>
                  )}
                </td>
                <td className="py-3 px-4">
                  <select
                    value={task.priority}
                    onChange={(e) => handlePriorityChange(task, e.target.value as Task['priority'])}
                    className={`rounded px-2 py-1 text-sm font-semibold ${priorityColors[task.priority]}`}
                  >
                    <option value="Low">Low</option>
                    <option value="Mid">Mid</option>
                    <option value="High">High</option>
                    <option value="Urgent">Urgent</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveTasksOverview;