import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCNQfrKyQbGC-8P6d7HxhGgtcq02dygJ0g",
    authDomain: "fourseo-94143.firebaseapp.com",
    databaseURL: "https://fourseo-94143-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "fourseo-94143",
    storageBucket: "fourseo-94143.appspot.com",
    messagingSenderId: "716392273936",
    appId: "1:716392273936:web:1c5c10f897338075dfd295"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);